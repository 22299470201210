<template>
   <section class="cont Rights-box">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>数据中台</el-breadcrumb-item>
            <el-breadcrumb-item>SCRM</el-breadcrumb-item>
            <el-breadcrumb-item>会员权益</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-box">
         <el-row class="table-box">
         <!-- 表格按钮 -->
            <div class="table-head-btn">
               <el-row></el-row>
               <el-row class="right-btn">
                  <el-button class="bg-gradient" type="primary" @click="handleBut('addlevel')">新建等级</el-button>
                  <el-button class="bg-gradient" type="primary" @click="handleService(false)">服务模板</el-button>
                  <el-button class="bg-gradient" type="primary" @click="handleBut('rule')">积分规则</el-button>
               </el-row>
            </div>
            <!-- 表格-->
            <el-table
                  v-loading="loading"
                  :data="tableData"
                  border fit
                  style="width: 100%"
                  :stripe="true"
                  :header-cell-style="{ background: '#f5f8f9' }">
               <el-table-column label="序号" type="index" width="90"></el-table-column>
               <el-table-column label="等级名称" width="200" prop="medalName"></el-table-column>
               <el-table-column label="升级所需积分" width="200" prop="highIntegral"></el-table-column>
               <el-table-column label="折扣" width="200">
                  <template slot-scope="scope">
                     <span>{{ scope.row.discount || 0 }}%</span>
                  </template>
               </el-table-column>
               <el-table-column label="会员服务" prop="Service"></el-table-column>
               <el-table-column label="操作" width="200">
                  <template slot-scope="scope">
                     <el-button type="text">
                        <el-link type="primary" @click="handleBut('editlevel',scope.row)">编辑</el-link>
                     </el-button>
                     <el-button type="text">
                        <el-link type="primary" @click="handleDel(true,scope.row.id)">删除</el-link>
                     </el-button>
                  </template>
               </el-table-column>
            </el-table>
         </el-row>
      </el-row>
      <!-- 新建/编辑等级/服务模板/积分规则  -->
      <el-dialog :title="dialogTitle | filterTitle " :visible.sync="isShow" width="550px" v-if="isShow">
         <el-form class="form-dialog-box" :model="ruleForm" :rules="rules" ref="ruleForm" v-if="dialogTitle !== 'service'">
         <!--  新增等级 -->
            <template v-if="dialogTitle === 'addlevel' ? true : dialogTitle === 'editlevel' ? true : false">
               <el-form-item label="等级名称：" prop="level">
                  <el-input class="width-300" v-model="ruleForm.level" placeholder="请输入等级名称" clearable></el-input>
               </el-form-item>
               <el-form-item label="折扣优惠：" prop="discount">
                  <el-input-number class="width-300" v-model="ruleForm.discount" :min="0" :max="100" clearable id="inpNumId"></el-input-number>
               </el-form-item>
               <el-form-item label="最高积分：" prop="maxIntegral">
                  <el-input class="width-300" v-model="ruleForm.maxIntegral" placeholder="请输入最高积分" clearable></el-input>
               </el-form-item>
               <el-row style="color: #ced1d8;font-size: 14px;margin: 0px 0px 0px 130px;">积分区间值不能重复，如果已含有则报错</el-row>
               <el-form-item label="会员服务：" prop="memberServices">
                  <el-row v-for="(item,index) in ruleForm.memberServices" :key="index">
                     {{item.rightsName}}
                  </el-row>
                  <span style="color: #409eff;" @click="handleService(true)">{{ruleForm.memberServices.length == 0 ? '添加' : '管理'}}</span>
               </el-form-item>
            </template>
         <!--  积分规则 -->
            <template v-if="dialogTitle === 'rule'">
               <el-alert title="积分配置如果更换，前面已获取已消费的不更改，修改后，后续订单按照新的积分规则运行" type="info" show-icon :closable="false" class="m-bottom-20"></el-alert>
               <el-form-item label="支付金额：" prop="payment">
                  <el-input class="width-300" v-model="ruleForm.payment" placeholder="请输入支付金额" clearable></el-input>
               </el-form-item>

               <el-row class="propTitle">用户通过商城支付现金购买统计的支付金额</el-row>
               <el-form-item label="获取积分：" prop="getIntegral">
                  <el-input class="width-300" v-model="ruleForm.getIntegral" placeholder="请输入获取积分" clearable></el-input>
               </el-form-item>
               <el-row class="propTitle" >每支付对应的现金可以获得的积分</el-row>
            </template>
         </el-form>
         <!-- 服务模板 -->
         <el-row v-if="dialogTitle === 'service'">
            <el-button class="bg-gradient" type="primary" size="small" @click="serTemAddEdit('add')"
                       style="float: right;margin: -30px 0px 20px 0px;">新增服务</el-button>
            <el-table
                  v-loading="serTem_loading"
                  :data="serTem_Table"
                  border fit
                  style="width: 100%"
                  :stripe="true"
                  :header-cell-style="{ background: '#f5f8f9' }">
               <el-table-column label="序号" type="index" width="90"></el-table-column>
               <el-table-column label="等级名称" prop="rightsName"></el-table-column>
               <el-table-column label="操作">
                  <template slot-scope="scope">
                     <el-button type="text">
                        <el-link type="primary" @click="serTemAddEdit('edit',scope.row)">编辑</el-link>
                     </el-button>
                     <el-button type="text">
                        <el-link type="primary" @click="serTemDel(scope.row)">删除</el-link>
                     </el-button>
                  </template>
               </el-table-column>
            </el-table>
         </el-row>
         <div slot="footer" class="dialog-footer">
            <el-button @click="isShow = false" :class="dialogTitle == 'service' ? 'bg-gradient' : ''">取消</el-button>
            <el-button class="bg-gradient" type="primary" @click="determine('ruleForm')" v-if="dialogTitle !== 'service'"> 确定 </el-button>
         </div>
      </el-dialog>

      <!-- 添加 -->
      <el-dialog title="服务管理" :visible.sync="serMan_show" width="550px" v-if="serMan_show">
         <el-table
               ref="multipleTable"
               v-loading="serMan_loading"
               :data="serMan_Table"
               tooltip-effect="dark"
               style="width: 100%"
               @selection-change="handleSelectionChange">
            <el-table-column  label="选择" type="selection" width="55"></el-table-column>
            <el-table-column  label="服务名称" prop="rightsName"></el-table-column>
         </el-table>
         <div slot="footer" class="dialog-footer" >
            <el-button class="bg-gradient" type="primary" @click="serManDetermine">确定</el-button>
            <el-button @click="serMan_show = false">取消</el-button>
         </div>
      </el-dialog>
      <!-- 新增服务 -->
      <el-dialog :title="serTem_Title === 'add' ? '新增服务' : '编辑服务'" :visible.sync="serTem_Show" width="550px" v-if="serTem_Show">
         <el-form :model="serTemForm" :rules="serTemRules" ref="serTemForm">
            <el-form-item label="服务名称：" prop="serTemName" style="margin-bottom: 0px">
               <el-input class="width-300" v-model="serTemForm.serTemName"  property="请输入" :maxlength="12" clearable></el-input>
            </el-form-item>
            <el-row style="color: #ced1d8;font-size: 14px;margin: 15px 0px 0px 95px;" >最大不可超过12个字</el-row>
         </el-form>
         <div slot="footer" class="dialog-footer">
            <el-button class="bg-gradient" type="primary" @click="serTemDetermine('serTemForm')">确定</el-button>
            <el-button @click="serTem_Show = false">取消</el-button>
         </div>
      </el-dialog>
   </section>
</template>

<script>
import { urlObj } from '@/api/interface'
import { verificationRule } from '@/common/js/common'
import { mapState } from 'vuex'
export default {
   name: "newRights",
   data(){
      //正整数 验证
      const validIntegral = (rule, value, callback) => {
         if (value === '') {
            switch (rule.field){
               case 'maxIntegral' :
                  callback(new Error('请输入最高积分'))
                  break;
               case 'payment':
                  callback(new Error('请输入支付金额'))
                  break;
               case 'getIntegral' :
                  callback(new Error('请输入获取积分'))
                  break;
            }
         }else if (verificationRule.integer(value)){
            callback(new Error('请输入正整数'))
         } else {
            callback()
         }
      }
      return{
         loading:true,              // 表格加载效果
         tableData:[],              // 表格数据
         isShow:false,              // 弹窗是否显示
         dialogTitle:"",            // 弹窗title
         /*新建等级 */
         ruleForm:{
            level:'',            //等级名称
            discount:"",              //折扣优惠
            maxIntegral:'',      //最高积分
            memberServices:[],   //会员服务
            payment:'',          //支付金额
            getIntegral:'',      // 获取积分
         },
         rules:{
            level:[{ required: true, message: '请输入等级名称', trigger: 'blur'}],
            discount:[{ required: true, message: '请输入折扣优惠', trigger: 'blur'}],
            maxIntegral:[{ required: true, validator: validIntegral, trigger: 'blur'}],
            payment:[{ required: true,validator: validIntegral, trigger: 'blur'}],
            getIntegral:[{ required: true,validator: validIntegral,trigger: 'blur'}],
         },
         page: 1,
         limit: 1,
         rowLevel:'',         //编辑row

         /*服务管理*/
         serMan_show:false,
         serMan_loading:true,
         serMan_Table:[],
         multipleSelection:[],      //多选表格选中的值

         /*服务模板 service*/
         serTem_Show:false,      //弹窗显示
         serTem_Title:'',        //弹窗文title
         serTem_id:'',           // 当前行新增/编辑id
         serTem_loading:true,    //
         serTem_Table:[],
         serTemForm:{
            serTemName:'',         //服务名称
         },
         serTemRules:{
            serTemName:[{ required: true, message: '请输入服务名称', trigger: 'blur'}],
         },

         del_title:'是否确认删除该会员权益信息？将永久无法恢复！',
         warning:'警告',
         Deleted:'已删除!'
      }
   },
   computed: {...mapState(['hotelInfo'])},
   watch: {
      hotelInfo(newVal,oldVal) {
         oldVal && newVal.id !== oldVal.id &&  this.getList()
      }
   },
   mounted() {
      this.limit = sessionStorage.getItem('pageSize') * 1
      this.getList()
   },
   methods:{
      //获取 会员权益 表格数据
      getList(){
         const url = urlObj.memberLevels + `?page=${this.page}&limit=999`
         const param = {  }
         if (JSON.parse(sessionStorage.getItem('hotelInfo')).accountType === 'HOTEL') {
            param.hotelId = this.hotelInfo.id
         }else {
            param.companyId = this.hotelInfo.storeId
         }
         this.$axios.post(url, param).then(res => {
            if (res.success) {
               this.loading = false
               let data = res.records
               // 处理会员服务字段
               data.forEach(item=>{
                  item.Service = []
                  item.medalRightsDTOS.forEach(items => {
                     item.Service.push(items.rightsName)
                  })
                  item.Service = item.Service.join('；')
               })
               this.tableData = data
            }
         })
      },
      //新建等级 addlevel/编辑等级 editlevel/积分规则 rule  弹窗显示
      handleBut(title,row){
         this.dialogTitle = title
         this.isShow = true
         if(title == 'addlevel' || title == 'editlevel'){
            // 给el-input-numbe-r设置suffix 添加%
            const span = document.createElement('span');
            const innerspan = document.createElement('span');
            span.setAttribute("class","el-input__suffix setSuffix-box");
            innerspan.setAttribute("class","el-input__suffix-inner");
            span.append(innerspan)
            innerspan.append('%')
            this.$nextTick(() =>{
               document.getElementById('inpNumId').lastElementChild.prepend(span);
            })
         }
         if(title == 'addlevel'){
            //新增 重置
            this.ruleForm.level =''
            this.ruleForm.discount = 0
            this.ruleForm.maxIntegral = ''
            this.ruleForm.memberServices = []
         }else if(title == 'editlevel'){
            //编辑回显
            this.rowLevel = row
            this.ruleForm.level = row.medalName //等级名称
            this.ruleForm.discount = row.discount || 0
            this.ruleForm.maxIntegral = row.highIntegral // 最高积分
            this.ruleForm.memberServices = row.medalRightsDTOS
            // console.log(this.ruleForm.memberServices)
         }else{
            // 获取 积分规则 默认比例
            const url = urlObj.getRuleIntegral
            const param = {}
            if (JSON.parse(sessionStorage.getItem('hotelInfo')).accountType === 'HOTEL') {
               param.hotelId = this.hotelInfo.id
            }else {
               param.companyId = this.hotelInfo.storeId
            }
            this.$axios.get(url, param).then(res => {
               if(res.success){
                  let data = res.records
                  this.ruleForm.payment = data.rulePayAmount // 支付金额
                  this.ruleForm.getIntegral = data.ruleIntegral // 获取积分
               }
            })
         }
      },
      // 获取服务模板数据
      handleService(bool){
         // bool判断 服务管理/服务模块
         if(bool){
            this.serMan_show = true
         }else{
            this.dialogTitle = 'service'
            this.isShow = true
         }
         const url = urlObj.memberRights + `?page=${this.page}&limit=${this.limit}`
         const param = { hotelId: this.hotelInfo.id }
         this.$axios.post(url, param).then(res => {
            if (res.success) {
               //判断 服务管理/服务模块
               if(bool){
                  this.serMan_loading = false
                  this.serMan_Table = res.records
                  // if(this.dialogTitle === 'addlevel'){}else{}
                  // 服务管理 多选回显
                  if(this.ruleForm.memberServices.length >0){
                     this.serMan_Table.forEach(item => {
                        this.ruleForm.memberServices.forEach(row => {
                           // this.ruleForm.memberServices初始对比id为rightsId，后为id，处理统一为rightsId和this.serMan_Table的id进行对比
                           if(!row.hasOwnProperty('rightsId')) row.rightsId = row.id
                           if(item.id == row.rightsId){
                              this.$nextTick(() => {
                                 this.$refs.multipleTable.toggleRowSelection(item,true)
                              });
                           }
                        })
                     })
                  }
               }else{
                  // 服务模块
                  this.serTem_loading = false
                  this.serTem_Table = res.records
               }
            }
         })
      },
      // 确定弹窗
      determine(formName){
         this.$refs[formName].validate((valid) => {
            if (valid) {
               if(this.dialogTitle === 'addlevel' || this.dialogTitle === 'editlevel'){
                  let medalCode = null;
                  let bool = true;
                  if(this.dialogTitle === 'addlevel'){
                      medalCode = Math.floor((Math.random()+Math.floor(Math.random()*9+1))*Math.pow(10,4))
                     this.tableData.forEach(item=>{
                        if(item.medalCode === medalCode) medalCode = Math.floor((Math.random()+Math.floor(Math.random()*9+1))*Math.pow(10,4))
                        // 积分区间值重复验证
                        if(this.ruleForm.maxIntegral == item.highIntegral) bool = false
                     })
                  }else{
                     medalCode = this.rowLevel.medalCode
                     // 积分区间值本身重复不验证，和其他值重复验证
                     this.tableData.forEach(item=>{
                        if(this.ruleForm.maxIntegral == item.highIntegral){
                           if(this.ruleForm.maxIntegral != this.rowLevel.highIntegral) bool = false
                        }
                     })
                  }
                  let rightsList = []
                  this.ruleForm.memberServices.forEach(item=>{
                     if( item.hasOwnProperty('rightsId') ){
                        rightsList.push(item.rightsId)
                     }else{
                        rightsList.push(item.id)
                     }
                  })
                  rightsList = rightsList.join(',')
                  let url = urlObj.saveMemberMedal
                  let param = {
                     medalName: this.ruleForm.level,//等级名称
                     medalCode: medalCode,          //给一个随机值传递给后台
                     highIntegral: this.ruleForm.maxIntegral, //最高积分
                     rightsIdList: rightsList,  //选中的数组
                     discount: this.ruleForm.discount,
                     hotelId: this.hotelInfo.id
                  }
                 if (JSON.parse(sessionStorage.getItem('hotelInfo')).accountType === 'HOTEL') {
                   param.hotelId = this.hotelInfo.id
                 }else {
                   param.companyId = this.hotelInfo.storeId
                 }
                 if(this.dialogTitle === 'editlevel') param.id = this.rowLevel.id
                 if(bool){
                    this.$axios.post( url , param ).then(res => {
                       if (res.success){
                          this.isShow = false
                          let messageTitle = ''
                          if(this.dialogTitle === 'addlevel'){
                             messageTitle = '新建等级成功'
                          }else{
                             messageTitle = '编辑等级成功'
                          }
                          this.$message({
                             type:'success',
                             message:messageTitle
                          })
                          this.getList()
                       }
                    })
                 }else{
                    this.$message({
                       type:'error',
                       message: "升级所需最高积分重复，请输入其他值"
                    })
                 }
               }else if(this.dialogTitle == 'rule'){
                  const url = urlObj.updateRuleIntegral
                  const param = {
                     rulePayAmount:this.ruleForm.payment,       // 支付金额
                     ruleIntegral:this.ruleForm.getIntegral    // 获取积分
                  }
                  if (JSON.parse(sessionStorage.getItem('hotelInfo')).accountType === 'HOTEL') {
                     param.hotelId = this.hotelInfo.id
                  }else {
                     param.companyId = this.hotelInfo.storeId
                  }
                  this.$axios.post(url, param).then(res => {
                     if (res.success) {
                        this.$message({
                           message:'积分规则设置成功',
                           type:'success'
                        })
                        this.isShow = false
                     }
                  })
               }
            } else {
               console.log('error submit!!');
               return false;
            }
         });
      },

      //服务管理 确定弹窗
      serManDetermine(){
         this.serMan_show = false
         this.ruleForm.memberServices = this.multipleSelection
      },
      //表格多选框
      handleSelectionChange(val) {
         this.multipleSelection = val;
      },
      //服务模块--新增/编辑服务
      serTemAddEdit(state,row){
         this.serTem_Show = true
         if(state === 'add'){
            this.serTem_Title = 'add'
            this.serTemForm.serTemName = ''
         }else{
            this.serTem_Title = 'edit'
            this.serTemForm.serTemName = row.rightsName
            this.serTem_id = row.id
         }
      },
      //服务模块--删除
      serTemDel(row){
         this.del_title = '注意：删除后如果有权益含有该服务，也会删除相应！'
         this.handleDel(false,row.id)
      },
      //服务模块--新增服务 确定
      serTemDetermine(formName){
           this.$refs[formName].validate((valid) => {
              if (valid) {
                 const url = urlObj.saveMemberRights
                 let param = {
                    rightsName: this.serTemForm.serTemName,
                    subTitle: this.serTemForm.serTemName,
                    hotelId: this.hotelInfo.id,
                    sortNo: 0,//排序号为0
                 }
                 if (this.serTem_Title === 'edit') param.id = this.serTem_id
                 this.$axios.post(url, param).then(res => {
                    if (res.success){
                       this.$message({
                          showClose: true,
                          message: this.serTem_Title === 'add' ? '添加成功！' : '编辑成功！',
                          type: 'success'
                       })
                       this.handleService(false)
                    }
                 })
                 this.serTem_Show = false
              } else {
                 console.log('error submit!!');
                 return false;
              }
           });
      },
      //删除
      handleDel(bool,id){
         this.$confirm(this.del_title, this.warning, {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
         }).then(() => {
            let url = ''
            if(bool){
               url = urlObj.delMemberMedal
            }else{//服务模块中的表格删除
               url = urlObj.delMemberRights
            }
            let param = { id }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: this.Deleted,
                     type: 'success'
                  })
                  if(bool){
                     this.getList()
                  }else{
                     this.handleService(false)
                     // this.service()
                  }
               }
            })
         })
      },
   },
   filters:{
      filterTitle(val){
         switch (val){
            case "addlevel":
               return '新建等级';
            case "editlevel":
               return '编辑等级';
            case "service":
               return '服务模块';
            case "rule":
               return '积分规则';
         }
      }
   }
}
</script>

<style scoped lang="scss">
.Rights-box{
   .propTitle {
      color: #ced1d8;
      font-size: 14px;
      margin: 0px 0px 10px 130px;
   }
   #inpNumId{
      ::v-deep .setSuffix-box{
         right:60px!important;
      }
   }
}


</style>
